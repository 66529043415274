@import 'loaders.css/src/animations/ball-beat.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input.focus-outline-none:focus {
  box-shadow: unset;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.parsed-html h3 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55/var(--tw-text-opacity));
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.parsed-html ol,
.parsed-html ul {
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-top: 0.5rem;
}

.parsed-html ul {
  list-style-type: disc;
}

.parsed-html a,
article blockquote a {
  color: #855bad;
}


.loader-inner.ball-beat {
  display: flex;
}

.active-menu-tab {
  display: flex;
  height: 100%;
  /* background-color: red; */
  align-items: center;
  // box-shadow: 0px 4px 0px #581c88;
  position: relative;
}

.active-menu-tab::after {
  min-height: 4px;
  height: 4px;
  min-width: 100%;
  width: 100%;
  background-color: #581c88;
  position: absolute;
  content: "";
  left: 0;
  bottom: -10px;
}

@media (max-width: 768px) {
  .mobile .active-menu-tab::after {
    bottom: 5px; /* Set bottom to 5px for mobile devices */
  }
}

.app-error {
  font-size: 0.85rem !important;
  color: #d21717 !important;
}

@media (min-width: 576px) {
  .no-scroll-bar::-webkit-scrollbar {
    display: none;
  }
}


safe-padding {
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
}