@tailwind base;
@tailwind components;
@tailwind utilities;


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif, cursive;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Chakra Petch", sans-serif;
}

input {
	padding: 10px 20px;
	border: 1px solid #ddd;
	border-radius: 3px;
	margin-bottom: 15px;
}

a {
	text-decoration: none;
}

.register,
.login,
.home,
.replies,
.modal {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 30px;
}

.registerTitle,
.loginTitle,
.homeTitle,
.repliesTitle {
	margin-bottom: 30px;
}

.registerForm,
.loginForm {
	width: 80%;
	display: flex;
	flex-direction: column;
}

.registerBtn,
.loginBtn,
.homeBtn,
.modalBtn {
	display: block;
	width: 200px;
	padding: 15px;
	cursor: pointer;
	outline: none;
	border: none;
	border-radius: 3px;
	font-size: 16px;
	font-weight: bold;
	color: #fffbf5;
	background-color: #674188;
	margin-bottom: 15px;
}

.homeForm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
	margin-bottom: 30px;
}

.home__container {
	display: flex;
	flex-direction: column;
	width: 75%;
}

.homeBtn {
	margin-bottom: 0px;
}

.thread__container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding: 20px;
}

.thread__item {
	padding: 20px;
	width: 80%;
	box-shadow: 0 0 1px 1px #c3acd0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.likes__container {
	display: flex;
	align-items: center;
	margin-right: 25px;
}

.likesBtn {
	height: 30px;
	color: #f99417;
	cursor: pointer;
}

.react__container {
	display: flex;
	align-items: center;
}

.modal {
	background-color: #ddd;
	position: fixed;
}

.modalInput {
	width: 100%;
	padding: 15px;
	margin-bottom: 15px;
}

.modal__content {
	background-color: #fff;
	width: 70%;
	padding: 30px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.modal__content>label {
	margin-bottom: 15px;
}

.navbar {
	width: 100%;
	padding: 10px 20px;
	background-color: #674188;
	display: flex;
	height: 10vh;
	align-items: center;
	justify-content: space-between;
}

.navbar>h2 {
	color: #fffbf5;
}

.navbarRight {
	display: flex;
	align-items: center;
}

.navbarRight>button {
	padding: 15px;
	width: 150px;
	background-color: #f99417;
	color: #fffbf5;
	font-size: 16px;
	border: none;
	outline: none;
	margin-left: 15px;
	cursor: pointer;
	border-radius: 3px;
}

/* tailwind custome classses */
.flex-scroll {
	min-height: 0;
	/* without min-height/height:0 flex:1 doesn't work */
	flex: 1;
	overflow: auto;
}

.bbCodeBlock.bbCodeQuote {
	font: 13px/1.231 arial, helvetica, clean, sans-serif;
	color: rgb(85, 84, 86) !important;
	word-wrap: break-word;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	list-style: none;
	font-size: 14px;
	font-family: 'proxima', Helvetica, Arial, sans-serif;
	line-height: 1.4;
	margin: 0;
	padding: 5px 10px;
	margin-bottom: 10px;
	margin-left: 10px;
	overflow: auto;
	background-color: rgb(244, 246, 248);
	border: 1px solid rgba(0, 0, 0, .12);
	border-left: 2px solid rgb(204, 0, 255);
	margin-top: 0;
	font-style: italic;
}

.bbCodeBlock.bbCodeQuote .attribution.type {
	font: 13px/1.231 arial, helvetica, clean, sans-serif;
	word-wrap: break-word;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	list-style: none;
	line-height: 1.4;
	margin: 0;
	padding: 0;
	font-family: 'proxima', Helvetica, Arial, sans-serif;
	font-size: 13px;
	color: rgb(60, 58, 61) !important;
	background-color: transparent;
	background-repeat: repeat-x;
	background-position: top;
	border-style: none;
	border-color: rgba(0, 0, 0, .12);
	margin-bottom: 15px;
	padding-top: 5px;
}

.bbCodeBlock.bbCodeQuote .attribution.type .AttributionLink {
	font: 13px/1.231 arial, helvetica, clean, sans-serif;
	word-wrap: break-word;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	list-style: none;
	line-height: 1.4;
	font-family: 'proxima', Helvetica, Arial, sans-serif;
	font-size: 15px;
	background-color: transparent;
	text-decoration: none;
	color: rgb(242, 0, 255);
	border-radius: 0;
	display: inline-block;
	min-width: 20px;
	width: 20px;
	margin-top: -50px !important;
	font-weight: bold;
	cursor: pointer;
	/* background-color: green; */
	text-align: center;
}

a.text-purple-dark:hover {
	color: #8b5cf6 !important;
}

.border-top {
	border-top: max(1px, 0.0625rem) solid #d0d7de !important;
}

input:focus-visible {
	outline-color: #581c87;
}

.accent-bg {
	background-color: #ffffff;
}

.accent-text-color {
	color: #581c87;
}

body {
	background-color: #dae0e6 !important;
}

.NoBreak {
	display: inline-block;
	line-height: 0.01em;
	vertical-align: middle;
}

.SelectQuoteContainer ul {
	list-style-type: circle;
	margin-left: 30px;
}

.tox-tinymce {
	border-radius: 2px !important;
}

input,
select,
textarea {
	background-color: #fbfbfb8f !important;
}

textarea.bg-white {
	background-color: white !important;
}

input.bg-gray-300 {
	background-color: #a5a3a32e !important;
}

input.bg-purple-900:checked {
	background-color: #581c87 !important;
}

// todo
// this is not working
select option[value=""] {
	color: #999999 !important;
}

[type='checkbox']:checked,
[type='radio']:checked {
	border-color: transparent;
	background-color: #581c87 !important;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
}


.fs-14px {
	font-size: 14px;
}

.jb-listing ul {
	padding-left: 2rem;
}

.jb-listing ul li {
	list-style: disc;
}

button:focus,
a:focus {
	outline-offset: 0px;
	outline-width: 0px;
	outline-style: unset;
	outline-color: unset;
}

.show-scrollbars-always {
	overflow-x: hidden;
}

.show-scrollbars-always::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 6px;
}

.show-scrollbars-always::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


@media only screen and (max-width: 768px) {
	.app-sm-fs {
		font-size: 1.2rem;
	}

	.app-xs-h {
		height: 2rem;
	}

	.app-sm-h {
		height: 3rem;
	}

	.app-sm-md-h {
		height: 3rem;
	}

	.app-mb-15 {
		margin-bottom: 15px;
	}

	body {
		font-size: 16px;
	}

	input[type="text"],
	textarea {
		font-size: inherit !important;
	}

	.show-scrollbars-always::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 4px;
	}

}

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Comfortaa:wght@500;600;700&family=DM+Serif+Display&family=Montserrat:wght@500;600&family=Poppins:wght@300&family=Quicksand:wght@500;600;700&family=Ubuntu:wght@400;500;700&family=Varela+Round&display=swap');

.logo-font {
	font-family: "Ubuntu" !important;
	font-weight: 700;
	color: #000000;
}

.logo-light {
	color: #4371c4ff;
}

.tox-statusbar__branding {
	display: none;
}

.pinned-item {
	background-color: #e7d6f4 !important;
}

.text-xxs {
	font-size: 0.65rem;
	line-height: 1rem;
}


@media (min-width: 3840px) {
	.max-h-screen-4k {
		max-height: 2160px;
	}
}


.parsed-html p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}